.courses {
    display: grid;
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    gap: 16px; 
    padding: 16px; 
    align-items: flex-start;
}

.filter-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    top: 0;
    gap: 5px;
}

.filter-bar button {
    margin-right: 10px; /* Space between buttons */
    padding: 5px 15px; /* Padding inside buttons */
    border: 2px solid gray; /* Border around buttons */
    color: gray; /* Text color */
    cursor: pointer; /* Cursor changes to pointer on hover */
  }