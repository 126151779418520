:root {
    --space-unit: 1em;
    --space-md: calc(1.25 * var(--space-unit));
    --space-lg: calc(2 * var(--space-unit));
    --component-padding: var(--space-md);
    --cd-color-1: hsl(206, 21%, 24%);
    --cd-color-2: hsl(205, 38%, 89%);
    --cd-color-3: hsl(207, 10%, 55%);
    --cd-color-4: hsl(111, 51%, 60%);
    --cd-color-5: hsl(356, 53%, 49%);
    --cd-color-6: hsl(47, 85%, 61%);
  }
  .cd-timeline {
    overflow: hidden;
    padding: 2rem 0;
  }
  .cd-timeline__container {
    position: relative;
    padding: 1.25rem 0;
  }
  .cd-timeline__container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: hsl(205, 38%, 89%);
  }
  @media (min-width: 64rem) {
    .cd-timeline__container::before {
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
  .cd-timeline__block {
    display: flex;
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;
  }
  .cd-timeline__block:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 64rem) {
    .cd-timeline__block:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  .cd-timeline__img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .cd-timeline__img img {
    max-width: 100%;
    width: auto;
    height: auto;
    border-radius: 50%;
  }
  @media (min-width: 64rem) {
    .cd-timeline__img {
      width: 125px;
      height: 125px;
      order: 1;
      margin-left: calc(5% - 30px);
      will-change: transform;
    }
    .cd-timeline__block:nth-child(even) .cd-timeline__img {
      margin-right: calc(5% - 30px);
    }
  }
  .cd-timeline__content {
    flex-grow: 1;
    position: relative;
    margin-left: 1.25rem;
    background: white;
    border-radius: 0.25rem;
    padding: 1.25rem;
    box-shadow: 0 10px 30px 0 rgba(68, 88, 144, 0.1);
  }
  .cd-timeline__content::before {
    display: none;
  }
  @media (min-width: 64rem) {
    .cd-timeline__content {
      width: 45%;
      flex-grow: 0;
      will-change: transform;
      margin: 0;
      --line-height-multiplier: 1.2;
    }
    .cd-timeline__content::before {
      top: 24px;
    }
    .cd-timeline__block:nth-child(odd) .cd-timeline__content::before {
      right: auto;
      left: 100%;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-left-color: hsl(0, 0%, 100%);
    }
  }
  @media (min-width: 64rem) {
    .cd-timeline__img--hidden,
    .cd-timeline__content--hidden {
      visibility: hidden;
    }
    .cd-timeline__img--bounce-in {
      -webkit-animation: cd-bounce-1 0.6s;
      animation: cd-bounce-1 0.6s;
    }
    .cd-timeline__content--bounce-in {
      -webkit-animation: cd-bounce-2 0.6s;
      animation: cd-bounce-2 0.6s;
    }
    .cd-timeline__block:nth-child(even) .cd-timeline__content--bounce-in {
      -webkit-animation-name: cd-bounce-2-inverse;
      animation-name: cd-bounce-2-inverse;
    }
  }
  @-webkit-keyframes cd-bounce-1 {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes cd-bounce-1 {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    60% {
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @-webkit-keyframes cd-bounce-2 {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px);
    }
    60% {
      opacity: 1;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes cd-bounce-2 {
    0% {
      opacity: 0;
      -webkit-transform: translateX(-100px);
      transform: translateX(-100px);
    }
    60% {
      opacity: 1;
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @-webkit-keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
    60% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  @keyframes cd-bounce-2-inverse {
    0% {
      opacity: 0;
      -webkit-transform: translateX(100px);
      transform: translateX(100px);
    }
    60% {
      opacity: 1;
      -webkit-transform: translateX(-20px);
      transform: translateX(-20px);
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
  