.course-review {
    margin: 5px;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: none;
}

.course-review .card-content {
    flex-grow: 1; 
}

.course-name,
.course-type,
.course-prereqs,
.course-comments {
    text-align: left;
    margin: 5px;
    color: gray;
}

.course-name {
    font-weight: bold;
    background-color: rgb(182, 221, 251);
    padding: 10px 5px;
    cursor: pointer;
}

.course-type,
.course-prereqs {
    font-weight: bold;
}

.expand-more-icon {
    color: gray;
}

.course-comments {
    display: flex;
    flex-direction: column;
    gap: 10px;
    line-height: 1.5;
    color: black;
}