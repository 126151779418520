.filter-box {
    position: relative;
    margin-right: 10px;
  }
  
  .filter-box button {
    width: 100%;
    margin-right: 10px;
    padding: 5px 15px;
    border: 2px solid black;
    color: gray;
    cursor: pointer;
  }
  
  
  .filter-button:hover {
    background-color: #d0d0d0;
    border-color: #707070;
  }
  
  .filter-options {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    border: 2px solid black;
    transform: translateX(-50%);
    border-top: none;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    cursor: pointer;
  }
  
  .dropdown-item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }

  .filter-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    top: 0;
    gap: 5px;
}

.filter-bar button {
    margin-right: 10px; /* Space between buttons */
    padding: 5px 15px; /* Padding inside buttons */
    border: 2px solid gray; /* Border around buttons */
    color: gray; /* Text color */
    cursor: pointer; /* Cursor changes to pointer on hover */
  }