.filter-tag {
    display: inline-flex;
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 12px;
    padding: 5px 10px;
    margin: 5px;
  }

  .filter-tag:hover {
    background-color: #d0d0d0;
    cursor: pointer;
  }
  
  .remove-button {
    background: none;
    border: none;
    padding-left: 5px;
    cursor: pointer;
    font-weight: bold;
  }